(async function () {
	const target = $dqs("#trustoo-card-carousel-widget");
	if (!target || (target && target.getAttribute("filled") !== null)) {
		return;
	} else {
		target.setAttribute("filled", true);
	}
	let shop_id;

	if (window.ShopifyAnalytics) {
		shop_id =
			window.ShopifyAnalytics &&
			ShopifyAnalytics.lib &&
			ShopifyAnalytics.lib.config &&
			ShopifyAnalytics.lib.config.Trekkie.defaultAttributes.shopId;
	}
	if (!shop_id) {
		const shopIdBlk = $dqs("#seal_shop_id");
		if (shopIdBlk) {
			shop_id = JSON.parse(document.querySelector("#seal_shop_id").textContent);
		}
	}

	const constructTime = new Date(TIME).getTime() / 1000;
	if (!$dqs(".tt-review-detail-js")) {
		const spt = document.createElement("script");
		let src = "";
		if (ENV === "local") {
			src = "../js/tt-review-detail.js?";
		} else {
			src = "https://" + STATIC_BASE_URL + "/static/js/tt-review-detail.js?";
		}
		src += constructTime;
		spt.setAttribute("src", src);
		spt.setAttribute("defer", true);
		spt.className = "tt-review-detail-js";
		spt.onload = function () {
			TTRevDetail.isInserted = true;
			TTRevDetail.insertTTReviewDetail(v);
			$dqs(".close-wrapper", v.revDetail).addEventListener(
				"click",
				function () {
					if (v.isSetTimer) {
						setTimer();
					}
				}
			);
		};
		document.head.appendChild(spt);
	}

	let html = "";
	let cardWidth = 0;
	let commonHigh = 0;
	let timer = null;
	let words = {};
	const isMobile = checkMobile();

	// document.body.insertAdjacentHTML('beforeend', '<div id="trustoo-mask" style="display:none"></div>')
	const v = {
		userSetting: {},
		isMobile,
		isMixReview: true,
		revDetailInfo: { page: 0, imageIndex: 0 },
		reviewsList: null,
		env: ENV,
		staticBaseUrl: STATIC_BASE_URL,
		constructTime,
		source: "carousel",
		sortType: "image-descending",
		isSetTimer: true,
		ratingIconSvg: "",
		badgeIconSvg: "",
	};

	if (typeof vstar_review_settings !== "undefined") {
		Object.assign(v.userSetting, vstar_review_settings);
	}

	const requestOption = {
		getSetting: {
			url: "get_card_carousel_setting",
			params: {
				shop_id,
			},
		},
		getLang: {
			url: "get_customer_self_define_lang",
			params: {
				shop_id,
				lang: "en",
			},
		},
		getReviews: {
			url: "get_product_reviews",
			params: {
				shop_id,
			},
		},
	};

	let sd = {},
		rd = [],
		reviewsList = [];
	let res = await request("getSetting");
	sd = res[1];
	const settingData = sd;
	if (!sd) {
		return;
	} else {
	}
	let column = settingData.max_columns;
	if (isMobile) {
		column = 1;
	}
	if (column === 2) {
		commonHigh = 210;
	} else if (column === 3) {
		commonHigh = 230;
	} else if (column === 4 || column === 1) {
		commonHigh = 250;
	}

	const dateType = sd.review_date_format_type;
	v.userSetting.review_date_format_type = dateType === 3 ? 4 : dateType;
	document.head.insertAdjacentHTML(
		"beforeend",
		'<style id="tt-swiper-style"></style>'
	);
	const swiperStyle = $dqs("#tt-swiper-style");
	swiperStyle.textContent += `
.tt-swiper {
  --column:${column};
  --common-height: ${commonHigh}px;
  --header-card-bd:${sd.header_card_bg_color};
  --header-star-color:${sd.header_star_color};
  --header-nostar-color:${sd.header_disabled_star_color};
  --header-text:${sd.header_text_color};
  --card-bd-color:${sd.widget_layout_card_bg_color};
  --star-color:${sd.widget_layout_star_color};
  --nostar-color:${sd.widget_layout_disabled_star_color};
  --text-color:${sd.widget_layout_text_color};
  --badge-color:${sd.widget_layout_badge_color};
  --badge-text-color:${sd.widget_layout_badge_text_color};
  --button-color:${sd.scroll_buttons_icon_color};
}
.tt-swiper .tt-swiper-header .vstar-star{
  --header-star-color:${sd.header_star_color};
  --header-nostar-color:${sd.header_disabled_star_color};
}
.tt-swiper .tt-swiper-wrapper .vstar-star{
  --star-color:${sd.widget_layout_star_color};
  --nostar-color:${sd.widget_layout_disabled_star_color};
}
#trustoo-review-detail{
	--star-color:${sd.widget_layout_star_color};
--nostar-color:${sd.widget_layout_disabled_star_color};
}
}
`;
	let sessionIcons = {};
	const ratingIconType = settingData.rating_icon_type || 1;
	const badgeIconType = settingData.verified_icon_type || 1;
	const iconString = gsSession("get", "ttRatingIcons");
	if (iconString) {
		sessionIcons = JSON.parse(iconString);
		v.ratingIconSvg = sessionIcons["star" + ratingIconType];
		v.badgeIconSvg = sessionIcons["badge" + badgeIconType];
	}
	if (ratingIconType === 1) {
		v.ratingIconSvg = getSvg("star");
	}
	if (badgeIconType === 1) {
		v.badgeIconSvg = getSvg("verified");
	}

	if (!v.ratingIconSvg || !v.badgeIconSvg) {
		const url = "https://cdn.vstar.app/static/customer/icons.json";
		new Promise((res, rej) => {
			httpRequest({ url, res, rej }, true);
		})
			.then(d => {
				const ratingIconName = getRatingIconName(ratingIconType);
				const icons = JSON.parse(d);
				v.ratingIconSvg = icons[ratingIconName];
				v.badgeIconSvg = icons["badge" + badgeIconType];
				sessionIcons["star" + ratingIconType] = v.ratingIconSvg;
				sessionIcons["badge" + badgeIconType] = v.badgeIconSvg;
				gsSession("set", "ttRatingIcons", JSON.stringify(sessionIcons));
				$dqsa(".vstar-star .pending").forEach(it => {
					it.innerHTML = v.ratingIconSvg;
					it.classList.remove("pending");
				});
				$dqsa(".tt-swiper .user-verified").forEach(it =>
					it.insertAdjacentHTML("afterbegin", v.badgeIconSvg)
				);
			})
			.catch(() => {
				v.ratingIconSvg = getSvg("star");
				v.badgeIconSvg = getSvg("verified");
			});
	}

	// 运行前执行用户需求自定义函数
	if (typeof trustooBeforeExecute !== "undefined") {
		trustooBeforeExecute(v, {});
	}

	let headerHtml = "",
		extraCls = "";
	if (settingData.is_show_header === 1) {
		headerHtml = `<div class="tt-swiper-header">
      <div class="swiper-header-title"></div>
      <div class="swiper-header-stars vstar-star"></div>
      <div class="swiper-header-rating"></div>
      <div class="swiper-header-count"></div>
    </div>`;
	} else {
		extraCls = "no-header";
	}
	const btnColor = sd.scroll_buttons_icon_color;
	html = `
    <div id="tt-carousel" class="tt-swiper column-${column}">
      ${headerHtml}
    </div>
  `;
	target.insertAdjacentHTML("beforeend", html);
	langReq = request("getLang");
	langReq.then(res => {
		const header = $dqs(".tt-swiper-header");
		words = JSON.parse(res[1].lang_json);
		v.lang = words;
		const headWords = words.card_carousel;
		$dqsa(".tt-review-verify").forEach(
			i => (i.textContent = words.verified_purchase)
		);
		if (sd.is_show_header === 1) {
			$dqs(".swiper-header-title", header).textContent = headWords.header_title;
			$dqs(".swiper-header-rating", header).textContent +=
				" " + headWords.header_review_rating_text;
			$dqs(".swiper-header-count", header).textContent +=
				" " + headWords.header_review_count_text;
		}
		$dqsa(".tt-card .tt-review-verify").forEach(
			i => (i.textContent = words.verified_purchase)
		);
	});

	if (sd.show_reviews === 1) {
		requestOption.getReviews.params.is_show_all = 1;
	} else if (sd.show_reviews === 2) {
		requestOption.getReviews.params.is_feature = 1;
	}
	let revRed = await request("getReviews");
	if (revRed[0]) {
		return;
	} else {
		rd = revRed[1];
		reviewsList = rd.list;
	}
	v.reviewsList = reviewsList;
	if (sd.is_show_header === 1) {
		let count = rd.total_rating.total_reviews;
		if (count > 1000) {
			let countStr = String(count);
			const len = countStr.length;
			count =
				countStr.substring(0, len - 3) + "," + countStr.substring(len - 3, len);
		}
		const ratingNode = $dqs(".tt-swiper-header .swiper-header-rating");
		const countNode = $dqs(".tt-swiper-header .swiper-header-count");
		$dqs(".swiper-header-stars", target).innerHTML = getStars(
			rd.total_rating.rating
		);
		ratingNode.textContent = rd.total_rating.rating + ratingNode.textContent;
		countNode.textContent = count + countNode.textContent;
	}
	reviewsList = rd.list;
	if (reviewsList.length === 0) {
		return;
	}

	const wrapper = document.getElementById("tt-carousel");
	wrapper.insertAdjacentHTML(
		"beforeend",
		`<div class="tt-swiper-wrapper ${extraCls}">
			<div id="tt-carousel-content">
				<div id="tt-carousel-list" class="tt-card-list">
				</div>
			</div>
			<div class="tt-swiper-pre tt-swiper-btn">${getSvg("pre", btnColor)}</div>
			<div class="tt-swiper-next tt-swiper-btn">${getSvg("next", btnColor)}</div>
		</div>`
	);

	try {
		sessionStorage.setItem(
			"trustoo_swiper_reviews",
			JSON.stringify(reviewsList)
		);
	} catch (error) {}

	let reviewsHtml = "";
	let verifyText = words.verified_purchase ? words.verified_purchase : "";
	reviewsList.forEach((i, inx) => {
		let verifiedHtml = "",
			reviewDate = "",
			reviewsCountry = "";
		if (sd.is_show_verified_purchase === 1 && i.verified_badge === 1) {
			verifiedHtml = `<div class="card-head-row2">
      ${v.badgeIconSvg}
      <span class="tt-review-verify">${verifyText}</span></div>`;
		}

		const d = i.commented_at.split("-");
		if (sd.review_date_format_type === 1) {
			reviewDate = d[1] + "/" + d[2] + "/" + d[0];
		} else if (sd.review_date_format_type === 2) {
			reviewDate = d[2] + "/" + d[1] + "/" + d[0];
		}

		if (sd.is_show_country_flag === 1) {
			reviewsCountry = i.author_country;
		}

		let imagesHtml = "";
		i.resources.forEach((it, index) => {
			let sty = "",
				wh = "";
			if (it.width < it.height) {
				sty = "width:100%;";
				const scale = (
					parseFloat((it.height / it.width).toFixed(2)) * 44
				).toFixed(2);
				wh = `width="44px" height="${scale}px"`;
			} else {
				const scale = (
					parseFloat((it.width / it.height).toFixed(2)) * 44
				).toFixed(2);
				sty = "height:100%;";
				wh = `width="${scale}px" height="44px"`;
			}
			if (index !== 0) {
				sty += "display:none";
			}
			imagesHtml += `<div class="resource-item tt-reviews-image">
				<img index="${index}" ${wh} alt="${index}" style="${sty}"
				 data-original="${it.thumb_src}" src="${it.thumb_src}" >
				</div>`;
		});
		imagesHtml = `<div class="resource-list">${imagesHtml}</div>`;

		reviewsHtml += `
    <div class="tt-card-wrapper">
      <div class="tt-card" no="${inx}">
        <div class="tt-card-head">
          <div class="card-head-row1">
            <div class="tt-review-name">${i.author}</div>
            <div class="tt-review-star vstar-star">${getStars(i.star)}</div>
          </div>
            ${verifiedHtml}
        </div>
        <div class="tt-card-body">
            ${i.content}
        </div>
        <div class="tt-card-footer">
				${imagesHtml}
          
          <div class="tt-card-info">
            <span class="tt-review-country">${reviewsCountry}</span>
            <span class="tt-review-date">${reviewDate}</span>
          </div>
        </div>
      </div>
    </div>
    `;
	});

	$dqs(".tt-card-list", target).insertAdjacentHTML("afterBegin", reviewsHtml);
	$dqsa(".tt-card .tt-review-verify").forEach(
		i => (i.textContent = words.verified_purchase)
	);
	// 运行前执行用户需求自定义函数
	if (typeof trustooAfterExecute !== "undefined") {
		trustooAfterExecute(v, undefined);
	}

	const onCarouselEmbed = new CustomEvent("onCarouselEmbed", {
		detail: {
			v,
		},
		bubbles: true,
		cancelable: true,
	});
	document.dispatchEvent(onCarouselEmbed);

	const indexStyle = document.createElement("link");
	indexStyle.rel = "stylesheet";
	indexStyle.href = `https://${STATIC_BASE_URL}/static/css/index.css`;
	document.head.appendChild(indexStyle);

	const setTimerDebounce = debounce(setTimer, 800);
	const destTimerDebounce = debounce(destroyedTimer, 800);

	const swiper = $dqs(".tt-card-list", wrapper);
	const content = $dqs("#tt-carousel-content", wrapper);
	const preBtn = $dqs(".tt-swiper-pre", wrapper);
	const nextBtn = $dqs(".tt-swiper-next", wrapper);
	let startClientX = 0;
	let cardCount = reviewsList.length;

	if (reviewsList.length <= column) {
		v.isSetTimer = false;
		wrapper.classList.add("hide-btn");
	}

	cardWidth = parseInt(getComputedStyle(content).width) / column;

	let isDragging = false;
	let currentTranslateX = 0;
	let tailCritical = cardWidth * (cardCount - column);

	const toLeftType = 1,
		toRightType = 2;

	setSwiperEvent();
	// ## 设置轮播事件
	function setSwiperEvent() {
		preBtn.onclick = function () {
			handleSwiperBtnClick(toLeftType);
		};
		nextBtn.onclick = function () {
			handleSwiperBtnClick(toRightType);
		};

		if (reviewsList.length > column) {
			if (v.isMobile) {
				swiper.addEventListener("touchstart", handleStart);
				swiper.addEventListener("touchmove", handleMove);
				swiper.addEventListener("touchend", handleEnd);
			} else {
				swiper.addEventListener("mousedown", handleStart);
				document.addEventListener("mousemove", handleMove);
				document.addEventListener("mouseup", handleEnd);
				swiper.onmouseenter = destTimerDebounce;
				swiper.onmouseleave = setTimerDebounce;

				// Prevent text selection while dragging
				swiper.addEventListener("dragstart", event => {
					event.preventDefault();
				});
			}
		}

		swiper.addEventListener("click", e => {
			const clientX = e?.clientX || e?.targetTouches[0].clientX;
			if (clientX - Math.round(startClientX) === 0 || startClientX === 0) {
				handleCardClick(e.target);
			}
		});
	}
	function handleCardClick(node) {
		document.body.classList.add("trustoo-open");
		const no = node.closest(".tt-card").getAttribute("no");
		v.revDetailInfo.no = no;
		v.revDetailInfo.imageIndex = 0;
		if (v.isSetTimer) {
			destroyedTimer();
		}
		TTRevDetail.showTTReviewDetail(
			v,
			"",
			reviewsList[no],
			"trustoo_swiper_review"
		);
	}

	function handleStart(event) {
		isDragging = true;
		const clientX = event?.clientX || event?.targetTouches[0].clientX;
		startX = clientX - currentTranslateX;
		startClientX = clientX;
		swiper.classList.add("dragging");
		destroyedTimer();
	}
	function handleMove(event) {
		if (isDragging) {
			const clientX = event?.clientX || event?.targetTouches[0].clientX;
			currentTranslateX = clientX - startX;
			swiper.style.transitionDuration = "0ms";
			swiper.style.transform = `translate3d(${currentTranslateX}px, 0, 0)`;
			if (-currentTranslateX > tailCritical || currentTranslateX > 0) {
				adjustCardPosition(clientX);
			}
		}
	}
	function handleEnd() {
		if (isDragging) {
			isDragging = false;
			let curCount = Math.round(Math.abs(currentTranslateX) / cardWidth);
			if (curCount >= cardCount - column) {
				curCount = cardCount - column;
			}
			swiper.classList.remove("dragging");
			swiper.style.transitionDuration = "300ms";
			currentTranslateX = -(curCount * cardWidth);
			swiper.style.transform = `translate3d(${currentTranslateX}px, 0, 0)`;
		}
	}

	function handleSwiperBtnClick(directionType) {
		toggleBtn(directionType);
		if (v.isSetTimer) {
			destroyedTimer();
			setTimerDebounce();
		}
	}
	// ## 循环切换卡片
	function adjustCardPosition(curClientX) {
		if (-currentTranslateX > tailCritical) {
			const copy = swiper.children[0].cloneNode(true);
			currentTranslateX = -(tailCritical - cardWidth);
			startX = curClientX - currentTranslateX;
			swiper.appendChild(copy);
			swiper.children[0].remove();
			swiper.style.cssText = `transform:translate3d(${currentTranslateX}px,0,0);transition-duration: 0ms;`;
		} else if (currentTranslateX > 0) {
			const lastNode = Array.from(swiper.children).at(-1);
			const copy = lastNode.cloneNode(true);
			startX = curClientX + cardWidth;
			currentTranslateX = -cardWidth;
			lastNode.remove();
			swiper.style.cssText = `transform:translate3d(${currentTranslateX}px,0,0);transition-duration: 0ms;`;
			swiper.insertAdjacentElement("afterbegin", copy);
		}
	}

	function setTimer() {
		if (
			timer === null &&
			$dqs("#trustoo-mask") &&
			getComputedStyle($dqs("#trustoo-mask")).display === "none"
		) {
			timer = setInterval(() => {
				toggleBtn(toRightType);
			}, 3000);
		}
	}
	function destroyedTimer() {
		if (timer) {
			clearInterval(timer);
			timer = null;
		}
	}
	function arrangeCards() {
		const width = window.innerWidth;
		if (width < 500) {
			column = 1;
		} else if (width < 968 && width >= 500) {
			column = 2;
		} else if (width >= 968) {
			column = settingData.max_columns;
		}
		cardWidth = parseInt(getComputedStyle(swiper).width) / column;
		tailCritical = cardWidth * (cardCount - column);
		if (cardCount > column) {
			if (v.isSetTimer) {
				if (!timer) {
					setTimer();
				}
			}
			wrapper.classList.remove("hide-btn");
		} else {
			if (v.isSetTimer) {
				destroyedTimer();
			}
			wrapper.classList.add("hide-btn");
		}
		scrollSwiper();
		let curCount = Math.round(Math.abs(currentTranslateX) / cardWidth);
		if (curCount >= cardCount - column) {
			curCount = cardCount - column;
		}
		currentTranslateX = -(curCount * cardWidth);
		swiper.style.cssText = `transform:translate3d(${currentTranslateX}px, 0, 0);transition-duration:300ms`;
	}
	v.arrangeCards = arrangeCards;
	//Mark 窗口缩小，列数变化
	window.addEventListener("resize", arrangeCards);
	if (v.isSetTimer) {
		setTimer();
	}

	function scrollSwiper() {
		swiper.style.cssText = `transform:translate3d(${currentTranslateX}px,0,0);`;
		let temp = null;
		if (-currentTranslateX > tailCritical) {
			temp = -tailCritical;
			adjustCardPosition();
		} else if (currentTranslateX > 0) {
			temp = 0;
			adjustCardPosition();
		}

		if (temp !== null) {
			currentTranslateX = temp;
			setTimeout(() => {
				swiper.style.cssText = `transform:translate3d(${currentTranslateX}px,0,0);transition-duration: 300ms;`;
			});
		}
	}

	function toggleBtn(directionType) {
		if (directionType === toLeftType) {
			currentTranslateX += cardWidth;
			scrollSwiper();
		} else if (directionType === toRightType) {
			currentTranslateX -= cardWidth;
			scrollSwiper();
		}
	}

	function $dqs(selector, context) {
		let node;
		if (context) {
			node = context.querySelector(selector);
		} else {
			node = document.querySelector(selector);
		}
		return node;
	}

	function $dqsa(selector, context) {
		let nodes;
		if (context) {
			nodes = context.querySelectorAll(selector);
		} else {
			nodes = document.querySelectorAll(selector);
		}
		return nodes;
	}

	//Mark 构造星星
	function getStars(rating) {
		// size参数有值，说明是要用svg星星图标
		const icon = v.ratingIconSvg;
		let extraCls = "";
		if (!icon) {
			extraCls = " pending";
		}
		const starString = `<div class="star-item${extraCls}">${icon}</div>`;
		const nostarString = `<div class="star-item nostar${extraCls}">${icon}</div>`;
		if (!parseInt(rating)) rating = 0;
		var starTag = "";
		var starNum = Math.floor(rating);

		for (var i = 0; i < starNum; i++) {
			starTag += starString;
		}
		if (rating > starNum) {
			const p = ((rating - starNum) / 1).toFixed(2) * 100;
			starTag += `<div class="star-item half"><div class="trustoo-swiper-nostar${extraCls}">${icon}</div><div style="width:${iconShowRatioAdjustment(
				p
			)}%" class="trustoo-swiper-star${extraCls}">${icon}</div></div>`;
			starNum++;
		}
		for (var i = starNum; i < 5; i++) {
			starTag += nostarString;
		}
		return starTag;
	}

	function iconShowRatioAdjustment(percentage) {
		let temp = parseInt(percentage) / 100;
		const ratio = parseInt(percentage) / 100;
		switch (sd.rating_icon_type) {
			case 13: {
				temp = 0.28 + parseFloat((0.44 * ratio).toFixed(2));
				break;
			}
			case 15: {
				temp = 0.215 + parseFloat((0.57 * ratio).toFixed(2));
				break;
			}
		}
		temp = temp * 100;
		return temp;
	}

	function getSvg(type, color = "#000") {
		let svg = "";
		switch (type) {
			case "verified": {
				svg = `<svg class="trustoo-badge-icon" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM5.30236 10.7724C5.45276 10.9239 5.64913 11 5.84629 11C6.04267 11 6.23982 10.9239 6.39022 10.7724L11.775 5.32765C12.075 5.02399 12.075 4.532 11.775 4.22834C11.4742 3.92389 10.9871 3.92389 10.6871 4.22834L5.84629 9.1223L3.31288 6.56115C3.01286 6.25748 2.52581 6.25748 2.22501 6.56115C1.925 6.86481 1.925 7.35758 2.22501 7.66125L5.30236 10.7724Z"/>
				</svg>`;
				break;
			}
			case "star": {
				svg = `<svg class="trustoo-rating-icon" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10 0L12.8214 6.11672L19.5106 6.90983L14.5651 11.4833L15.8779 18.0902L10 14.8L4.12215 18.0902L5.43493 11.4833L0.489435 6.90983L7.17863 6.11672L10 0Z"/>
				</svg>
				`;
				break;
			}
			case "pre": {
				svg = `<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.76807 14.6367C7.54864 14.6367 7.32917 14.555 7.16175 14.3909L0.876159 8.23107C0.54128 7.90346 0.54128 7.37141 0.876159 7.04324L7.16175 0.882852C7.49663 0.554674 8.03896 0.554674 8.37384 0.882852C8.70872 1.21046 8.70872 1.74251 8.37384 2.07069L2.69457 7.63685L8.37384 13.2025C8.70872 13.5307 8.70872 14.0621 8.37384 14.3903C8.20697 14.555 7.98751 14.6367 7.76807 14.6367Z" fill="${color}"/>
      </svg>
      `;
				break;
			}
			case "next": {
				svg = `<svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.23193 0.363281C1.45136 0.363281 1.67082 0.445047 1.83825 0.60912L8.12384 6.76893C8.45872 7.09654 8.45872 7.62859 8.12384 7.95676L1.83825 14.1171C1.50337 14.4453 0.961039 14.4453 0.62616 14.1171C0.291281 13.7895 0.291281 13.2575 0.62616 12.9293L6.30543 7.36315L0.626159 1.79753C0.29128 1.46935 0.29128 0.937871 0.626158 0.609693C0.79303 0.445047 1.01249 0.363281 1.23193 0.363281Z" fill="${color}"/>
      </svg>
      `;
				break;
			}
		}
		return svg;
	}

	function getRatingIconName(type) {
		const codes = [
			"star",
			"rounded",
			"spikes",
			"surrounded",
			"fire",
			"heart",
			"diamond",
			"crown",
			"trophy",
			"shirt",
			"skirt",
			"bag",
			"tie",
			"cup",
			"leaf",
			"seedling",
			"footprint",
			"chef",
		];
		return codes[type - 1];
	}

	function checkMobile() {
		let ua = navigator.userAgent,
			isAndroid = /(?:Android)/.test(ua),
			isFireFox = /(?:Firefox)/.test(ua),
			isTablet =
				/(?:iPad|PlayBook)/.test(ua) ||
				(isAndroid && !/(?:Mobile)/.test(ua)) ||
				(isFireFox && /(?:Tablet)/.test(ua)),
			isPhone = /(?:iPhone)/.test(ua) && !isTablet;
		return isAndroid || isPhone;
	}

	// 封装xhr请求
	function httpRequest(option, isStatic = false) {
		const { params, method = "GET" } = option;
		const success = option.res,
			fail = option.rej;
		// 获取xmlHttpRequest对象
		let xmlHttp = new XMLHttpRequest();
		// API路由
		// pro  api.trustoo.io
		// test rtest.sealapps.com
		// dev rtest.sealapps.com
		var baseUrl = "https://" + BASE_URL + "/api/v1/reviews/";
		let url = isStatic ? option.url : baseUrl + option.url;
		let paramsStr = "";
		if (typeof params === "object") {
			paramsStr = Object.keys(params).reduce(
				(pre, item) => pre + "&" + item + "=" + params[item],
				""
			);
		}

		// post请求方式
		if (method == "POST") {
			xmlHttp.open(method, url, true);
			// 添加http头，发送信息至服务器时的内容编码类型
			// xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			// 发送数据，请求体数据
			// xmlHttp.send(`product_id=${params.product_id}&shop_id=${params.shop_id}`);
			if (paramsStr && !isOss) {
				paramsStr = paramsStr.slice(1);
			} else {
				paramsStr = params;
			}
			// 发送数据
			xmlHttp.send(paramsStr);
		} else if (method == "GET") {
			if (paramsStr) {
				paramsStr = paramsStr.replace("&", "?");
				url += paramsStr;
			}
			xmlHttp.open(method, url, true);
			xmlHttp.send();
		}
		xmlHttp.onreadystatechange = () => {
			if (xmlHttp.readyState == 4) {
				let resData = xmlHttp.responseText;
				if (xmlHttp.status == 200 || xmlHttp.status == 203)
					success && success(resData);
				else
					fail &&
						fail({ responseText: resData, statusText: xmlHttp.statusText });
			}
		};
	}
	//Mark promise封装请求
	function request(key) {
		let lang = "";
		const option = requestOption[key];
		if (typeof vstar_review_settings !== "undefined") {
			if (vstar_review_settings.auto_switch_language === 1) {
				lang = Shopify.locale ? Shopify.locale : "";
			} else if (vstar_review_settings.is_translate_review === 1) {
				lang = vstar_review_settings.language;
			}
		}
		if (key === "getReviews") {
			option.params.t = new Date().getTime();
			option.params.limit = 12;
			option.params.page = 1;
			option.params.sort_by = v.sortType;
			option.params.scene = 1;
			if (lang) {
				option.params.lang = lang;
			}
		} else if (key === "getLang") {
			if (lang) {
				option.params.lang = lang;
			} else if (sd.card_lang) {
				option.params.lang = sd.card_lang;
			}
		}

		return new Promise((res, rej) => {
			Object.assign(option, { res, rej });
			httpRequest(option);
		})
			.then(data => {
				data = JSON.parse(data);
				const code = data.code;
				if (code === -1) {
					throw new Error(data.message);
				}
				data = data.data;
				return [null, data];
			})
			.catch(err => {
				return [{ error: err }];
			});
	}

	// 防抖
	function debounce(fn, delay) {
		var timeout = null;
		return function (...args) {
			if (timeout) {
				clearTimeout(timeout);
			}

			timeout = setTimeout(() => {
				fn(...args);
			}, delay);
		};
	}
	function gsSession(method, name, data) {
		if (method === "set") {
			try {
				sessionStorage.setItem(name, JSON.stringify(data));
			} catch (error) {}
		} else {
			return JSON.parse(sessionStorage.getItem(name));
		}
	}
})();
